import React, { useEffect } from 'react';
import './App.css';

function App() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://embed.chatnode.ai/82fc9481-ac4e-4b13-8880-372524a3af9b";
    script.async = true; // Vous pouvez essayer de commenter cette ligne pour un chargement synchrone
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="App" style={styles.app}>
      <header className="App-header" style={styles.header}>
        <div style={styles.logoContainer}>
          <img src={`${process.env.PUBLIC_URL}/LOGO.png`} alt="Décodex Sinaiticus Logo" style={styles.logo} />
          <h1 style={styles.title}>Décodex Leningrad</h1>
        </div>
      </header>
      <main style={styles.main}>
        <section style={styles.section}>
          <h2 style={styles.heading}>Problématique</h2>
          <p style={styles.paragraph}>
            De nos jours, il existe de nombreuses traductions du Tanakh. Une infinité. Mais que dit réellement le texte original ? Certaines traductions, en effet, peuvent modifier l'idée originale, amenant à diverses confusions. Quoi de mieux que la VO pour comprendre un film en profondeur ? <br></br> <br></br> Il en va de même avec le Tanakh.
          </p>
        </section>
        <section style={styles.section}>
          <h2 style={styles.heading}>Solution</h2>
          <p style={styles.paragraph}>
            Connaissez-vous Codex Leningrad ? C'est la version originale la plus ancienne, complète et atuthentique du Tanakh parue au XIe siècle. Mais, malheureusmeent, tout le monde ne sait pas lire l'hébreux. <br></br> <br></br>Voici donc Décodex Leningrad : un chatbot IA là pour fournir les versets originaux en hébreu original et en phonétique. <br></br> <br></br>Il vous explique ensuite, mot après mot ou ligne après ligne, chaque signification pour une compréhension optimale au plus proche de la verite. <br></br> <br></br>Ce que voulait reellement exprimer Hachem.
          </p>
        </section>
        <section style={styles.section}>
          <h2 style={styles.heading}>Comment ça marche ?</h2>
          <p style={styles.paragraph}>
            Cliquez sur l'icône du chatbot, demandez le verset ou le chapitre requis, et discutez avec Décodex. Il vous donnera le verset ou chapitre original demandé et vous l'expliquera patiemment.
          </p>
        </section>
        <script 
  type="text/javascript" 
  src="https://embed.chatnode.ai/82fc9481-ac4e-4b13-8880-372524a3af9b/popup.js"
  async
></script>
      </main>
    </div>
  );
}

const styles = {
  app: {
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    color: '#333',
  },
  header: {
    backgroundColor: '#283593', // Dark blue color
    padding: '20px 0',
    textAlign: 'center',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: '80%',
    maxWidth: '700px',
    height: 'auto',
  },
  title: {
    fontSize: '32px',
    color: '#fff', // White color to contrast with the dark background
    margin: '10px 0 0 0', // Add space above the title, remove bottom margin
  },
  main: {
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    textAlign: 'left',
  },
  section: {
    marginBottom: '40px',
  },
  heading: {
    fontSize: '24px',
    marginBottom: '10px',
    color: '#283593', // Matching the header color
  },
  paragraph: {
    fontSize: '18px',
    lineHeight: '1.8',
  },
};

export default App;